<script setup>
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'

const store = useStore()

// computed
const getOptions = computed(() => store.getters['system/getOptionsPfosten'])
// methods
function onChangeOption (stepId, optionId, e) {
  store.dispatch('system/setOptionsValue', {
    optionId: optionId,
    stepId: stepId,
    checked: e.target.checked
  })
}
</script>

<template>
  <div class="row" v-if="getOptions">
    <div class="col-md-6">
      <p><strong>Ihre Optionen:</strong></p>
      <div v-for="(option, index) in getOptions.options" :key="index">
        <div class="form-check" v-if="option.type === 'select'">
          <input type="radio"
                 :id="option.id"
                 :value="option.id"
                 :name="option.dname"
                 :data-step="getOptions.stepId"
                 :checked="option.selected"
                 class="form-check-input"
                 @change="onChangeOption(getOptions.stepId, option.id, $event)"
                 :disabled="option.disabled">
          <label class="form-check-label" :for="option.id">
            {{ option.name }} <tooltip :tooltip="option.tooltip" style="display:inline-block"><span class="fa fa-info-circle text-info"></span></tooltip>
          </label>
        </div>

        <div class="custom-control custom-checkbox" v-else-if="option.type === 'check'">
          <input type="checkbox"
                 :id="option.id"
                 :value="option.id"
                 :name="getByOptionStepByName.id"
                 :checked="option.selected"
                 class="custom-control-input"
                 @change="onChangeOption(getOptions.stepId, option.id, $event)"
                 :disabled="option.disabled"
          >
          <label class="custom-control-label" :for="option.id">
            {{ option.name }} <tooltip :tooltip="option.tooltip" style="display:inline-block"><span class="fa fa-info-circle text-info"></span></tooltip>
          </label>
        </div>

      </div>
    </div>
  </div>

</template>
